import Facebook from "./../asset/img/facebook.svg";
import Instagram from "./../asset/img/instagram.svg";
import { Link } from "react-router-dom";
import Linkedin from "./../asset/img/linkedin.svg";
import React from "react";
import Twitter from "./../asset/img/twitter.svg";
import Whatsapp from "./../asset/img/whatsapp.svg";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-6 mb-3">
            <h2>About</h2>
            <ul>
              <li>
                <Link to="/about">Company</Link>
              </li>
              <li>
                <a
                  href="https://s3-eu-west-1.amazonaws.com/african-trade-invest/PRIVACY_POLICY_ATI.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="#"
                  href="https://s3-eu-west-1.amazonaws.com/african-trade-invest/TERMS+OF+SERVICE_ATI.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-6 mb-3">
            <h2>Service</h2>
            <ul>
              <li>
                <Link to="/investments">Investments</Link>
              </li>
              <li>
                <a href="#">Fund a Project</a>
              </li>
              <li>
                <a href="/partnership">Partnerships</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-6 mb-3">
            <h2>Support</h2>
            <ul>
              <li>
                <Link to="/contact">Help Desk</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12">
            <h2>Connect</h2>
            <ul>
              <li>info@africantradeinvest.com</li>
              <li>+234 (0) 814 470 8860</li>
            </ul>
            <a
              href="http://linkedin.com/company/african-trade-invest-ati"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icon" src={Linkedin} />
            </a>
            <a
              href="https://www.facebook.com/africantradeinvest/?ref=bookmarks"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icon" src={Facebook} />
            </a>
            <a
              href="https://www.instagram.com/africantradeinvest/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icon" src={Instagram} />
            </a>
            <a
              href="https://twitter.com/TradeAti"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icon" src={Whatsapp} />
            </a>
            {/* <a href="#">
              <img className="social-icon" src={Twitter} />
            </a> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 mt-3">
            <p className="footer-info">
              Africantradeinvest.com is a property of ATIDOTCOM LIMITED, a
              company duly registered under the laws of the Fedral Republic of
              Nigeria. Africantradeinvest.com is the platform created by Traders
              of Africa Limited for its investors.
            </p>
            <p>© 2021 African Trade Invest, All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
