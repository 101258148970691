import { Link, useHistory } from "react-router-dom";
import { setPageTitle, truncate } from "../../utils";
import { useEffect, useState } from "react";

import CRight from "./asset/img/c-right.svg";
import CloseIcon from "./asset/img/x-mark.svg";
import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";
import Phone2 from "./asset/img/iphone.svg";
import React from "react";
import Request from "../../apiClient";
import { toast } from "react-toastify";

function Investment() {
  const history = useHistory();
  const [loading, setLoading] = useState([]);
  const [investmentType, setInvestmentType] = useState("");
  const [duration, setDuration] = useState(12);
  const [commodities, setCommodities] = useState([]);
  const interestRate = {
    usd: 5,
    ngn: 8,
  };
  const [currency, setCurrency] = useState("NGN");
  const [amountInvesting, setAmountInvesting] = useState(0);

  useEffect(() => {
    setPageTitle("Investments");
    getCommodities();
  }, []);

  const getCommodities = () => {
    setLoading(true);
    Request("GET", "commodity/all")
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          setCommodities(response.data.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error);
        }
      });
  };

  const checkout = () => {
    if (investmentType === "Project") {
      if (currency === "NGN" && amountInvesting < 1000000) {
        toast.error("Amount is below minium accepted ");
        return;
      }
      if (currency === "USD" && amountInvesting < 5000) {
        toast.error("Amount is below minium accepted ");
        return;
      }
      window.location.href = `/investment-summary/${investmentType}/${amountInvesting}/${currency}/4-months`;
      return;
    }
    if (currency === "NGN" && amountInvesting < 2000000) {
      toast.error("Amount is below minium accepted ");
      return;
    }
    if (currency === "USD" && amountInvesting < 5000) {
      toast.error("Amount is below minium accepted ");
      return;
    }
    if (duration < 4) {
      toast.error("Invalid duration set ");
      return;
    }
    window.location.href = `/investment-summary/${investmentType}/${amountInvesting}/${currency}/${duration}-${"months"}`;
  };

  return (
    <div>
      <section id="commodity-pg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="commodity-wrap">
                <div className="row">
                  <div className="col-lg-5" id="commodity-txt">
                    <h4>
                      Get great
                      <br />
                      value for your money
                    </h4>
                    <p>
                      Invest in a plan now and start earning big <br />
                      facilitating African trade
                    </p>
                    {/* <button className="btn btn-success">
                      Get Started
                      <img class="act-icon" src={CheRight} />
                    </button> */}
                  </div>
                  <div className="col-lg-7" align="right">
                    <img className="hero-img img-fluid" src={Phone2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="inv-plan">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h1 class="inv-title">Available Investment plans</h1>
              <p class="inv-subtitle">Select from the plans below to invest</p>
            </div>
            <div class="col-lg-4">
              <div class="inv-plan-wrap bg-color1">
                {/* <img src="img/.png" /> */}
                <h1>Project</h1>
                <p>
                  Project Investments are for 4 months. Share of Profit is paid
                  at the end of the tenure.
                </p>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                  onClick={() => {
                    setInvestmentType("Project");
                  }}
                >
                  Invest Now <img class="inv-icon" src={CRight} />
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="inv-plan-wrap bg-color2">
                {/* <img src="img/.png" /> */}
                <h1>Premium</h1>
                <p>
                  Here, you invest in a pool of funds. Minimum term is 12
                  months. Share of Profit is paid every 4 months.
                </p>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                  onClick={() => {
                    setInvestmentType("Premium");
                  }}
                >
                  Invest Now <img class="inv-icon" src={CRight} />
                </a>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="inv-plan-wrap bg-color2">
                {/* <img src="img/.png" /> */}
                <h1>Premium Plus</h1>
                <p>
                  Here, you invest in a pool of funds to facilitate trade. Share
                  of Profit is Compounded
                </p>
                <a
                  href="#"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                  onClick={() => {
                    setInvestmentType("Premium-Plus");
                  }}
                >
                  Invest Now <img class="inv-icon" src={CRight} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* INVESTMENT MODAL */}

      <div
        className="modal fade bd-example-modal-lg invest-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img width="20" src={CloseIcon} />
                </span>
              </button>
            </div>
            <div class="modal-body">
              {/* Investment Details */}

              <div className="row">
                <div className="col-lg-12">
                  <div className="invest-modal-dtls">
                    <div className="inv-info mb-4">
                      {/* <div className="inv-info-media">
                        <img src="./img/1.png" class="" alt="..." />
                      </div> */}
                      <div className="inv-info-dtls">
                        <h2>{investmentType} Investment</h2>
                        {/* <p>Minimum Investment is NGN 1,000,000 or USD 5,000</p> */}
                      </div>
                    </div>

                    <div className="form-group">
                      <label for="exampleFormControlSelect1">
                        What currency would you want to invest in?
                      </label>{" "}
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked={currency === "NGN"}
                          onClick={() => {
                            setCurrency("NGN");
                          }}
                        />
                        <label
                          className="form-check-label label"
                          for="exampleRadios1"
                        >
                          Naira
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="option2"
                          checked={currency === "USD"}
                          onClick={() => {
                            setCurrency("USD");
                          }}
                        />
                        <label
                          className="form-check-label label"
                          for="exampleRadios2"
                        >
                          Dollar
                        </label>
                      </div>
                    </div>

                    <div className="invest-dtls-rhs">
                      <div className="invest-dtls-block">
                        <h4>Share of Profit (SOP)</h4>
                        <h3>
                          {currency === "NGN"
                            ? interestRate.ngn
                            : interestRate.usd}
                          %
                        </h3>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            Enter Investment amount
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(e) => {
                              setAmountInvesting(e.target.value);
                            }}
                            required
                          />
                          <small
                            id="emailHelp"
                            className="form-text text-muted"
                          >
                            Minimum Investment is NGN{" "}
                            {investmentType === "Project"
                              ? "1,000,000"
                              : "2,000,000"}{" "}
                            or USD 5,000
                          </small>
                        </div>
                      </div>
                      {investmentType !== "Project" && (
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="exampleFormControlSelect1">
                              {investmentType !== "Project"
                                ? "Select Duration"
                                : "4 Months"}
                            </label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) => setDuration(e.target.value)}
                            >
                              <option className="dropdown-item" value="12">
                                12 Months
                              </option>
                              <option className="dropdown-item" value="16">
                                16 Months
                              </option>
                              <option className="dropdown-item" value="20">
                                20 Months
                              </option>
                              <option className="dropdown-item" value="24">
                                24 Months
                              </option>
                              <option className="dropdown-item" value="28">
                                28 Months
                              </option>
                              <option className="dropdown-item" value="32">
                                32 Months
                              </option>
                              <option className="dropdown-item" value="36">
                                36 Months
                              </option>
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            At maturity you earn
                          </label>
                          <NumberFormat
                            value={Number(
                              ((currency === "NGN"
                                ? interestRate.ngn
                                : interestRate.usd) *
                                amountInvesting) /
                                100 +
                                Number(amountInvesting)
                            ).toFixed()}
                            className="form-control"
                            style={{
                              height: "50px",
                              backgroundColor: "#ebebeb",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={currency === "NGN" ? "₦" : "$"}
                          />
                        </div>
                      </div>
                    </div>
                    {/* // add duration for non premium and premium plus */}
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        checkout();
                      }}
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
                <div className="col">
                  <p className="investterm">
                    By clicking on continue, you agree to all our{" "}
                    <span>
                      {" "}
                      <a
                        href="https://s3-eu-west-1.amazonaws.com/african-trade-invest/TERMS+OF+SERVICE_ATI.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Terms and Conditions{" "}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      {/* <section id="commodities">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Commodities we invest in</h1>
              <p className="com-subtitle">
              </p>
            </div>
            <div className="align-items-center">
              {loading && (
                <Loader
                  type="TailSpin"
                  color="#00a82d"
                  height={100}
                  width={100}
                />
              )}
            </div>
            {commodities
              .filter((opt) => opt.isAvailable)
              .map((data, index) => {
                return (
                  <div
                    index={index}
                    className="col-lg-3"
                    onClick={() => {
                      history.push(`/investment-details/${data.commodityID}`);
                    }}
                  >
                    <div className="com-wrap shadow-sm">
                      <div className="com-img">
                        <img src={data.defaultMedia} alt={data.commodityID} />
                      </div>
                      <div className="com-details">
                        <h2>{truncate(data.title, 19)}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: truncate(data.description, 80),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Investment;
