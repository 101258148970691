import axios from 'axios';

const api = axios.create({
  baseURL: 'https://admin.api.africantradeinvest.com/api/v1',
  // baseURL: 'http://localhost:8081/api/v1',
});

const Request = (
  method,
  url,
  data,
  headers
) => {
  return api.request({
    method,
    url,
    data,
    headers,
  });
};

export default Request;