import "react-toggle/style.css";

import { getToken, getUser, setPageTitle } from "../../utils";
import { useEffect, useState } from "react";

import CoinImage from "./asset/img/coin.png";
import EarnImage from "./asset/img/earnings.png";
import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";
import React from "react";
import Request from "../../apiClient";
import SurfaceImage from "./asset/img/surface1.png";
import Toggle from "react-toggle";

function Home() {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    const auth = getUser();
    if (auth) {
      setAuth(JSON.parse(auth));
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [showUSD, setShowUSD] = useState(false);
  const [dashboard, setDashboard] = useState([]);

  useEffect(() => {
    setPageTitle("Home");
    if (getUser() !== "") {
      getDashboard();
    }
  }, []);

  const getDashboard = () => {
    setLoading(true);
    Request("GET", "investor/dashboard/", null, { Authorization: getToken() })
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          setDashboard(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loader-state">
          <Loader type="TailSpin" color="#00a82d" height={100} width={100} />
        </div>
      ) : (
        <div className="rhs">
          {/* <div className="intro">
            <div className="switch-currency">
              <p>{dashboard.nextPayout === null ? 'Get your money working' : 'Your money is working for you'}</p>
              <div className="currency">
                <Toggle
                  id='currency'
                  defaultChecked={showUSD}
                  onChange={() => {
                    setShowUSD(!showUSD)
                  }} />
                <label htmlFor='currency'> Show USD balance</label>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-12 col-lg-4 box">
              <div
                id="card"
                className="br"
                style={{ backgroundColor: "#13AE3C" }}
              >
                <div className="bg-oval1" />
                <div className="bg-oval2" />
                <div className="icon-block">
                  <img className="img-fluid" src={CoinImage} />
                </div>
                <br />
                <h4>
                  {showUSD ? (
                    <NumberFormat
                      value={dashboard.usdTotalInvestment.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  ) : (
                    <NumberFormat
                      value={dashboard.ngnTotalInvestment}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  )}
                </h4>
                <p>Total Investment</p>
              </div>
            </div>
            <div className="col-12 col-lg-4 box">
              <div
                id="card"
                className="br"
                style={{ backgroundColor: "#FCB014" }}
              >
                <div className="bg-oval3" />
                <div className="bg-oval4" />
                <div className="icon-block">
                  <img className="img-fluid" src={EarnImage} />
                </div>
                <br />
                <h4>
                  {showUSD ? (
                    <NumberFormat
                      value={dashboard.usdTotalEarned.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  ) : (
                    <NumberFormat
                      value={dashboard.ngnTotalEarned}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  )}
                </h4>
                <p>Total Earnings</p>
              </div>
            </div>
            <div className="col-12 col-lg-4 box">
              <div
                id="card"
                className="br"
                style={{ backgroundColor: "#13AE3C" }}
              >
                <div className="bg-oval1" />
                <div className="bg-oval2" />
                <div className="icon-block">
                  <img className="img-fluid" src={SurfaceImage} />
                </div>
                <br />
                <h4>
                  {dashboard.nextPayout === null || dashboard.nextPayout === ""
                    ? "No Investments"
                    : dashboard.nextPayout}
                </h4>
                <p>Next Payout</p>
              </div>
            </div>
          </div>
          <div id="plans" className="shadow-sm">
            <div className="row">
              <div class="col-12 mb-3">
                <h5>Get your money working</h5>
                <h2>Choose a Plan</h2>
              </div>
              <div className="col-lg-4 col-12 plans">
                <div className="inv-plan">
                  <h6>Project</h6>
                  <p>
                    Project Investments are for 4 months. Share of Profit is
                    paid at the end of the tenure.
                  </p>
                  <div className="line" />
                  <p
                    className="link"
                    onClick={() => {
                      window.location = "/investments";
                    }}
                  >
                    Invest Now
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 plans">
                <div className="inv-plan">
                  <h6>Premium</h6>
                  <p>
                    Here, you invest in a pool of funds. Minimum term is 12
                    months. Share of Profit is paid every 4 months.
                  </p>
                  <div className="line" />
                  <p
                    className="link"
                    onClick={() => {
                      window.location = "/premium-investment";
                    }}
                  >
                    Invest Now
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-12 plans">
                <div className="inv-plan">
                  <h6>Premium Plus</h6>
                  <p>
                    Here, you invest in a pool of funds to facilitate trade.
                    Share of Profit is Compounded
                  </p>
                  <div className="line" />
                  <p
                    className="link"
                    onClick={() => {
                      window.location = "/premium-investment";
                    }}
                  >
                    Invest Now
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <h2 className="mt-5 mb-4">My Investments</h2>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
