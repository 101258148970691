import {
  IoAppsOutline,
  IoCashOutline,
  IoHomeOutline,
  IoListOutline,
  IoLogInOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { Link, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";

import { IconContext } from "react-icons";
import Logo from "./asset/img/logo.svg";
import NotifyIcon from "./asset/img/notify-icon.svg";
import { getUser } from "../../utils";
import { isActive } from "../../utils";
import { logout } from "../../utils";

function Header() {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    const auth = getUser();
    if (auth) {
      setAuth(JSON.parse(auth));
    }
  }, []);
  return (
    <nav className="navbar navbar-expand-md bg-white navbar-light fixed-top desktop-nav-menu">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img className="logo" src={Logo} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          <div className="navbar-nav ml-auto desktop-menu">
            <h6 className="welcome-title">
              {`${auth.user && auth.user.firstName} ${
                auth.user && auth.user.lastName
              }`}
            </h6>
          </div>
          {/* <div className="ml-auto icon-box desktop-menu">
            <img className="icon" src={NotifyIcon} style={{ width: "18px" }} />
          </div> */}

          <div className="mobile-menu">
            <ul class="navbar-nav">
              {/* <li>
                <h6 className="welcome-title">
                  {`${auth.user && auth.user.firstName} ${
                    auth.user && auth.user.lastName
                  }`}
                </h6>
              </li> */}
              <li className={`${isActive("/dashboard")}`}>
                <Link to={`/dashboard`}>
                  <IoHomeOutline />
                  Home
                </Link>
              </li>
              <li className={`${isActive("/dashboard/investments")}`}>
                <Link to={`/dashboard/investments`}>
                  <IoAppsOutline />
                  My Investment
                </Link>
              </li>
              <li className={`${isActive("/dashboard/payouts")}`}>
                <Link to={`/dashboard/payouts`}>
                  <IoCashOutline />
                  Payouts
                </Link>
              </li>
              <li className={`${isActive("/dashboard/profile")}`}>
                <Link to={`/dashboard/profile`}>
                  <IoPersonOutline />
                  Profile
                </Link>
              </li>
              <li className="_link">
                <a
                  onClick={() => {
                    logout();
                  }}
                >
                  <IoLogInOutline />
                  logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Header;
