import {
  IoCall,
  IoLocationOutline,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoMailUnreadOutline,
} from "react-icons/io5";
import { Link, useRouteMatch } from "react-router-dom";

import { IconContext } from "react-icons";
import React from "react";
import { setPageTitle } from "../../utils";
import { useEffect } from "react";

function ContactUs() {
  useEffect(() => {
    setPageTitle("Contact us");
  }, []);
  return (
    <IconContext.Provider value={{ size: "1.3em" }}>
      <div>
        <section id="contact-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className>
                  <div className="row">
                    <div className="col-lg-5" id="header-txt">
                      <h4>Contact Us</h4>
                      <p>
                        Invest in a product now and start earning big from the
                        comfort of your home
                      </p>
                      <ul className="details">
                        <li>
                          <a href="#">
                            <IoLogoFacebook />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <IoLogoTwitter />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <IoLogoInstagram />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <IoLogoLinkedin />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact-pg">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="contact-text">
                  <h6>How can we help you?</h6>
                  <p>
                    Send us an email or give us a call and we'll get back to
                    you.
                  </p>
                </div>
                <form className="contact-form">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>Full name</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="email"
                          className="form-control"
                          id=""
                          name=""
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Phone number</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                          name=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Your subject</label>
                    <input type="text" className="form-control" id="" name="" />
                  </div>
                  <div className="form-group">
                    <label>Description:</label>
                    <textarea
                      className="form-control"
                      rows={4}
                      id=""
                      defaultValue={""}
                    />
                  </div>
                  <button
                    className="btn btn-success my-2 my-sm-0 contact-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="col-lg-5">
                <div className="info">
                  <h6>Get in touch</h6>
                  <div className="line" />
                  <ul className="details">
                    <li>
                      <a href="#">
                        <IoLocationOutline style={{ marginRight: "12px" }} />
                        Address
                      </a>
                    </li>
                  </ul>
                  <p>
                    No 7/9 Adebisi Oyenola Street, Idado Estate, Igbon Effon
                    lekki.
                  </p>
                  <div className="line" />
                  <ul className="details">
                    <li>
                      <a href="#">
                        <IoMailUnreadOutline style={{ marginRight: "12px" }} />
                        Email
                      </a>
                    </li>
                  </ul>
                  <p>info@tradersofafrica.com</p>
                  <div className="line" />
                  <ul className="details">
                    <li>
                      <a href="#">
                        <IoCall style={{ marginRight: "12px" }} />
                        Phone Number
                      </a>
                    </li>
                  </ul>
                  <p>+234 807 996 7564</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </IconContext.Provider>
  );
}

export default ContactUs;
