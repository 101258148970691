export const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;

export const setPageTitle = (title) => {
    document.title = 'African Trade Invest | '+title
}

export const logout = () => {
    if (window.confirm("Are you sure you want to logout")) {
        localStorage.clear()
        window.location.href = '/'  
    }
}

export const getUser = () => {
    return localStorage.getItem(atiAuthKey) || ''
}

export const getToken = () => {
    return `Bearer ${JSON.parse(getUser()).token}`
}

export const unAuthenticated = () => {
    return getUser() === ''
}

export const isActive = (path) => {
    return window.location.pathname === path ? 'active' : ''
}

export const atiAuthKey = 'atiAuth'