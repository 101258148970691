import { getToken, getUser, setPageTitle } from "../../utils";
import { useEffect, useState } from "react";

import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";
import React from "react";
import Request from "../../apiClient";
import moment from "moment";

function Payout() {
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPageTitle("My Payouts");
    if (getUser() !== "") {
      getPayouts();
    }
  }, []);
  const getPayouts = () => {
    setLoading(true);
    Request("GET", "investor/payouts", null, { Authorization: getToken() })
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          setPayouts(response.data.data.nextPayout);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      {loading ? (
        <div className="loader-state">
          <Loader type="TailSpin" color="#00a82d" height={100} width={100} />
        </div>
      ) : (
        <div className="rhs shadow-sm" id="inv-pg">
          <div className="payouts">
            <p>Payouts</p>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered inv-table">
              <thead>
                <tr className="header">
                  <th>Investment</th>
                  <th>Duration</th>
                  <th>Amount Payable</th>
                  <th>Interest Rate</th>
                  <th>Pay Date</th>
                  <th>Paid</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {payouts.map((data, index) => {
                  return (
                    <tr className="body">
                      <td>{data.type}</td>
                      <td>
                        {data.duration} {data.durationPeriod}
                      </td>
                      <td>
                        <NumberFormat
                          value={data.payoutDue.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={data.currency + " "}
                        />
                      </td>
                      <td>{data.interestRate}%</td>
                      <td>{moment(data.maturityDate).format("YYYY/MM/DD")}</td>
                      <td>{data.paidInvestor ? "Paid" : "Not paid"}</td>
                      
                      <td>
                        <span className="badge badge-pill badge-warning">
                          {data.status}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Payout;
