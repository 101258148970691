import { useEffect, useState } from "react";

import ArrowRight from "./asset/img/arrow-right.svg";
import Boy from "./asset/img/boy.svg";
import Checked from "./asset/img/checked.svg";
import Execute from "./asset/img/execute.svg";
import IntroVideo from "./asset/img/video.png";
import Invest from "./asset/img/invest.svg";
import NumberFormat from "react-number-format";
import Order from "./asset/img/order.svg";
import Phone from "./asset/img/ati.gif";
import PoolInv from "./asset/img/pool-inv.jpg";
import ProInv from "./asset/img/pro-inv2.jpg";
import CheRight from "./asset/img/arrow-right-white.svg";

import Phone2 from "./asset/img/iphone.svg";
import CRight from "./asset/img/c-right.svg";
import Africa from "./asset/img/africa.svg";
import Video from "./asset/img/fbg2.jpg";
import HeroMap from "./asset/img/map.svg";

import React from "react";
import Testimonial from "./asset/img/testimonial.jpg";
import { setPageTitle } from "../../utils";
import { useHistory } from "react-router-dom";

function Home() {
  const history = useHistory();
  const [sopPreview, setsopPreview] = useState(1000000);
  useEffect(() => {
    setPageTitle("Welcome");
  }, []);

  return (
    <div>
      {/* Hero section */}
      <section id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="home-info">
                <div>
                  <h2>
                    Invest in
                    <br /> African Trade
                  </h2>
                  <p>
                    Support small farming communities by facilitating trade
                    deals <br />
                    and earn as high as 24% annually in Share of Profit.
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-success home-cta-btn"
                    onClick={() => {
                      history.push("/investments");
                    }}
                  >
                    Invest now
                    <img src={CheRight} />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6" align="right">
              <img className="hvr-icon homemap" src={HeroMap} />
            </div>
          </div>
        </div>
      </section>
      {/* End of Hero section */}
      {/* Staistics section */}
      <section id="statistics">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="stats">
                <div className="row">
                  <div className="col-lg-2 col-6">
                    <div className="stats-wrap">
                      <p>Total Investments</p>
                      <h3>
                        6,273,311 <span>USD</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-2 col-6">
                    <div className="stats-wrap">
                      <p>Total Payout</p>
                      <h3>
                        307,531 <span>USD</span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-2 col-6">
                    <div className="stats-wrap">
                      <p>Active Investors</p>
                      <h3>107</h3>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="learn-more ml-auto">
                      <p>
                        Still not convinced about African Trade Invest,
                        <br />{" "}
                        <a className="hvr-icon-forward" href="/about">
                          <span>Learn more</span>
                          <img className="hvr-icon" src={ArrowRight} />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Staistics section */}
      {/* offering */}
      <section id="offering">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Why African Trade Invest?</h1>
              <p>
                We are committed to facilitating African trade through adequate
                funding and execution to create a sustainable business, yielding
                great and stable returns. Three years and counting, we have
                funded trade deals worth $5 million and have never defaulted.{" "}
                {/* And we will never...! */}
              </p>
              <p>
                Using our extensive experience, we developed a model whereby we
                delineate each order as Projects. Funding needed for each
                project are broken down into units and offered to individuals,
                companies and cooperatives all over the world to invest in.
              </p>
              {/* <p>Through our dedicated teams and partners, we verify and supervise each project to ensure strict compliance and timely execution. Most importantly, our investors receive updates on the progress of projects on their personalized dashboards.</p> */}
            </div>
            <div className="col-lg-6" align="right">
              <a href="#">
                <img className="africa img-fluid" src={Video} />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* offering  */}
      <section id="how-it-works">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-12">
              <h1>How it works</h1>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="how-info">
                    <img src={Order} />
                    <h2>Order Received</h2>
                    <p>
                      When an order is received, Funding needed for this project
                      is broken down into units and offered to individuals,
                      companies and cooperatives all over the world to invest.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="how-info">
                    <img src={Invest} />
                    <h2>You Invest</h2>
                    <p>
                      You can invest by buying as many units available. Your
                      investment can either be in Nigerian Naira (NGN) or United
                      States Dollars (USD).
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="how-info">
                    <img src={Execute} />
                    <h2>We Execute</h2>
                    <p>
                      Our dedicated team verify and supervise each project to
                      ensure strict compliance and timely execution. Most
                      importantly, our investors receive updates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </section>
      {/* Our Investments*/}
      <section id="ati-investments">
        <div class="container">
          <div class="row">
            <div class="col-lg-5" align="left">
              <img class="img-fluid mobile-phone" src={Phone2} />
            </div>

            <div class="col-lg-7">
              <div class="row">
                <div class="col-lg-12">
                  <h2>Investments Plans</h2>
                  <p>
                    You can invest in 3 of our Investment plans. Project
                    Investment, Premium Investments and Premium Plus Investment.
                    Investment may be in Nigerian Naira NGN) or United States
                    Dollars USD).
                  </p>
                  <p>
                    <span>
                      Currently, Share of Profit (SOP) for Naira Investment is
                      8% and 5% for Dollar Investments. This applies to both
                      Project Investment and Premium Investments.
                    </span>
                  </p>

                  <div class="inv-type">
                    <div class="media">
                      <img src={CRight} class="mr-3" alt="..." />
                      <div class="media-body">
                        <p>
                          <span>Project investment</span> - a one-time
                          investment for a period of 4 months. At maturity,
                          Principal and SOP is paid out.
                        </p>
                      </div>
                    </div>
                    <div class="media">
                      <img src={CRight} class="mr-3" alt="..." />
                      <div class="media-body">
                        <p>
                          <span>Premium investment</span> - runs for a minimum
                          of 1 year, with Share of Profit (SOP) paid out every 4
                          months.
                        </p>
                      </div>
                    </div>
                    <div class="media">
                      <img src={CRight} class="mr-3" alt="..." />
                      <div class="media-body">
                        <p>
                          <span>Premium Plus</span> – runs for 1 year whilst
                          Share of Profit is compounded.
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        history.push("/investments");
                      }}
                      class="btn btn-success"
                    >
                      Invest now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Our Investments  */}
      {/* Calculator */}
      <section id="calculator">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="calc-wrap">
                <h1 className="calc-title">Do the Maths</h1>
                <form>
                  <div className="form-group">
                    {/* Refactor input to contain prefix in front of currency */}
                    <input
                      type="text"
                      className="form-control"
                      id="inv-amount"
                      placeholder="Enter Investment Amount here"
                      value={sopPreview}
                      onChange={(val) => {
                        setsopPreview(val.target.value);
                      }}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Minimum Investment is NGN 1,000,000
                    </small>
                  </div>
                </form>
                <div className="calc-details">
                  <div className="row">
                    <div className="col-lg-7">
                      <h1>In 4 months you earn</h1>

                      <h3>
                        <NumberFormat
                          value={Number(
                            (8 / 100) * sopPreview + Number(sopPreview)
                          ).toFixed()}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </h3>
                    </div>
                    <div className="col-lg-5">
                      <h1>Share of Profit</h1>
                      <h3>8%</h3>
                    </div>
                  </div>
                </div>
                <a href="#">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      history.push("investments");
                    }}
                  >
                    Invest Now
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <h2>Calculate your earnings</h2>
              <p>
                Calculate how much you earn when you invest in ATI. Enter an
                amount into the calculator to see your Share of Profit (SOP)
              </p>
              <div className="calc-aces">
                <h4>
                  Interest paid as at when due, without delay{" "}
                  <img src={Checked} />
                </h4>
                <h4>
                  No hidden fees or charges <img src={Checked} />
                </h4>
                <h4>
                  Zero maintenance fees <img src={Checked} />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Calculator */}
      {/* Testimonial */}
      <section id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img-bg">
                <img className="img-fluid" src={Testimonial} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="testimonial-wrap">
                <h1>Stories form our Investors</h1>
                <div
                  id="carouselExampleSlidesOnly"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <p>
                        I have been an investor in ATI since February this year.
                        I am quite impressed by their professional and
                        conscientious approach to business and the good return
                        on investment thus far. <br />
                        <br />I have no doubt that their business model will
                        sustain impressive growth in Nigeria and in other
                        African markets in which they operate. I have no
                        reservations whatsoever in recommending ATI to anyone
                        seeking a good investment opportunity.
                      </p>
                      <div className="testimonial-info">
                        <div className="testimonial-img">
                          <img src={Boy} />
                        </div>
                        <h2>Emeka Anasiudu</h2>
                        <h3>Investor</h3>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <p>
                        My name is Judith Oronsaye and I’ve had a wonderful time
                        with African Trade Invest It all started when I was
                        looking for a safe heaven to invest my fund and African
                        Trade Invest came calling. Since then it’s been peace
                        all through, my investment is safe and I’ve had my
                        returns on time and I’m glad I’m here.
                        <br />
                        <br />
                        African Trade Invest, I will and I’m still recommending
                        to everyone. Not a ponzi but a real business focused
                        company
                      </p>
                      <div className="testimonial-info">
                        <div className="testimonial-img">
                          <img src={Boy} />
                        </div>
                        <h2>Judith Oronsaye</h2>
                        <h3>Investor</h3>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <p>
                        I was very excited to learn that African Trade Invest
                        was adopting digital transformation with the launch of
                        its app. What intrigued so much is the Value Co-creation
                        that African Trade Invest employs to develop products,
                        concepts and initiatives with its investors. The prompt
                        service delivery and light-speed responsiveness is
                        second to none.
                        <br />
                        <br />
                        I'm glad to be an investor in African Trade Invest and
                        will recommend them anytime, any day and anywhere!
                      </p>
                      <div className="testimonial-info">
                        <div className="testimonial-img">
                          <img src={Boy} />
                        </div>
                        <h2>Bassey Umoh</h2>
                        <h3>Investor</h3>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <p>
                        I will like to use this opportunity to say
                        congratulations to TOFA for this milestone. I will be
                        quick to say your services are awesome.
                        <br />
                        <br />
                        One thing I appreciated mostly in my dealings with you
                        was the communication. At every step you kept in touch
                        and ensured I knew what was happening with my
                        investment. Keep it up and strive for more.
                      </p>
                      <div className="testimonial-info">
                        <div className="testimonial-img">
                          <img src={Boy} />
                        </div>
                        <h2>Adaeze Ocho</h2>
                        <h3>Investor</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Testimonial */}
      <section id="invest-today">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="invest-today-wrap">
                <div className="row">
                  <div className="col-lg-8">
                    <p>Get Started Now</p>
                    <h2>Start investing today</h2>
                  </div>
                  <div className="col-lg-4" align="right">
                    <button className="btn btn-success">
                      Sign up for free
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
