import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import React from "react";
import Routes from "./Routes";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
      <>
      <Routes />
      <ToastContainer />
      </>
  );
}

export default App;
