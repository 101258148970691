import { setPageTitle, truncate } from "../../utils";
import { useEffect, useState } from "react";

import { Link } from 'react-router-dom';
import React from "react";

function InvestmentSuccess() {
  return (
    <section id="inv-success">
      <div className="container">
        <div className="row align-items-center" style={{ height: "90vh" }}>
          <div className="col-lg-12" align="center">
            <h2>
              Your Investment has been booked. We will send you a confirmation
              email.
            </h2>
            <a href="/dashboard">Go to Dashboard</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InvestmentSuccess;
