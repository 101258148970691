import {
  IoCardOutline,
  IoHelpCircle,
  IoListOutline,
  IoLockClosed,
  IoPersonCircleSharp,
  IoShieldCheckmarkSharp,
} from "react-icons/io5";
import { Link, useRouteMatch } from "react-router-dom";
import { atiAuthKey, getToken, getUser, setPageTitle } from "../../utils";
import { useEffect, useState } from "react";

import BoyImage from "./asset/img/boy.svg";
import Cancel from "./asset/img/close.svg";
import { IconContext } from "react-icons";
import ImageAvatar from "./asset/img/profile.svg";
import Loader from "react-loader-spinner";
import React from "react";
import Request from "../../apiClient";
import { toast } from "react-toastify";

function Profile() {
  const [profile, setProfile] = useState({});
  const [user, setUser] = useState({
    loading: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    setPageTitle("My Account");
    if (getUser() !== "") {
      getProfile();
    }
  }, []);
  const getProfile = () => {
    setLoading(true);
    Request("GET", "investor/profile", null, { Authorization: getToken() })
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          setProfile(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateAccount = (e) => {
    e.preventDefault();
    setUser({
      ...user,
      loading: true,
    });
    Request(
      "PUT",
      "investor/profile",
      {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        houseAddress: user.houseAddress,
        nextOfKin: user.nextOfKin,
        nextOfKinContact: user.nextOfKinContact,
        nextOfKinRelationship: user.nextOfKinRelationship,
      },
      { Authorization: getToken() }
    )
      .then((response) => {
        if (response.status === 200) {
          const auth = JSON.parse(getUser());
          getProfile();
          localStorage.setItem(atiAuthKey, JSON.stringify(auth));
          setUser({
            ...user,
            loading: false,
          });
          toast.success("Account updated");
        } else {
          setUser({
            ...user,
            loading: false,
          });
          toast.error("Failed to update account");
        }
      })
      .catch((e) => {
        setUser({
          ...user,
          loading: false,
        });
        toast.error("Failed to update account");
        console.error(e);
      });
  };
  var date = new Date();
  var currentYear = date.getFullYear();

  return (
    <>
      <div className="rhs shadow-sm" id="user-pg">
        <div className="row" id="profile">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-12">
                <div className="name">
                  <h6>{`${profile && profile.firstName} ${
                    profile && profile.lastName
                  }`}</h6>
                  <p>{profile && profile.email}</p>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="duration">
                  <h6>Active member</h6>
                  <p>{currentYear}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="photo shadow-sm">
              <img
                className="icon_photo"
                src={BoyImage}
                width={100}
                height={126}
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="row" id="profile-nav">
          <div className="col-sm-12">
            <IconContext.Provider
              value={{ size: "1.4em", style: { marginRight: "15%" } }}
            >
              <ul className="profile-menu">
                <li className="shadow-sm" onClick={() => setOpenModal(true)}>
                  <a href="#">
                    <span>
                      <IoPersonCircleSharp />
                    </span>
                    Personal Details
                  </a>
                </li>
                <li className="shadow-sm">
                  <Link onClick={() => (window.location.href = "/contact")}>
                    <span>
                      <IoHelpCircle />
                    </span>
                    Help and Support
                  </Link>
                </li>
                <li className="shadow-sm">
                  <a
                    href="https://s3-eu-west-1.amazonaws.com/african-trade-invest/TERMS+OF+SERVICE_ATI.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <IoShieldCheckmarkSharp />
                    </span>
                    Terms and Condition
                  </a>
                </li>
                <li className="shadow-sm">
                  <a href="#">
                    <span>
                      <IoLockClosed />
                    </span>
                    Change Password
                  </a>
                </li>
              </ul>
            </IconContext.Provider>
          </div>
        </div>
      </div>
      {openModal && (
        <div className="row">
          <div className="col-sm-4 shadow-lg" id="personal">
            <span
              onClick={() => setOpenModal(false)}
              style={{ cursor: "pointer" }}
            >
              <img className="cancel" src={Cancel} />
            </span>
            <h6>
              <b>Personal Details</b>
            </h6>
            <img className="avatar" src={ImageAvatar} />
            <div className="row">
              <div className="col-sm-9" id="form">
                <p className="mb-3">
                  <b>Edit your personal details below</b>
                </p>
                <form className="profile-dash-form" action="/action_page.php">
                  <div className="form-group">
                    <label htmlFor="email">First Name:</label>
                    <input
                      type="email"
                      className="form-control"
                      value={profile.firstName}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          firstName: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Last Name:</label>
                    <input
                      type="email"
                      className="form-control"
                      value={profile.lastName}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          lastName: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">Email:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={profile.email}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          email: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">Phone:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={profile.phone}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          phone: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">Address:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={profile.houseAddress}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          houseAddress: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <br />
                  <p className="mb-3">
                    <b>Edit information about Next of kin</b>
                  </p>
                  <div className="form-group">
                    <label htmlFor="email">Full Name:</label>
                    <input
                      type="email"
                      className="form-control"
                      value={profile.nextOfKin}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          nextOfKin: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">Contact:</label>
                    <input
                      type="password"
                      className="form-control"
                      value={profile.nextOfKinContact}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          nextOfKinContact: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">Relationship:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={profile.nextOfKinRelationship}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          nextOfKinRelationship: e.target.value,
                          changed: false,
                        });
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-success my-2 my-sm-0 dash-profile-btn"
                    type="submit"
                    onClick={(e) => {
                      updateAccount(e);
                    }}
                    disabled={user.changed}
                  >
                    Update Profile
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="overlay" />
    </>
  );
}

export default Profile;
