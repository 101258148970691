import React from "react";

function Product() {
  return (
    <section id="product-pg">
      <div className="container">
        <div className="product-all">
          <div className="row">
            <div className="col-lg-5">
              <div className="product">
                <img
                  src="img/maize.png"
                  className="img-fluid"
                  alt=""
                  id="maize"
                />
              </div>
            </div>
            <div className="col-lg-7 summary">
              <h5>Maize</h5>
              <p>
                The cashew seed is often considered a nut in the culinary sense;
                the cashew nut is eaten on its own, used in recipes or processed
                into cashew seed or cashew butter. like the tree the nut is
                often only called cashew
              </p>
              <p>
                The cashew seed is often considered a nut in the culinary sense;
                the cashew nut is eaten on its own, used in recipes or processed
                into cashew seed or cashew butter.
              </p>
              <ul className="list">
                <li>
                  <a href="#">
                    <i className="fas fa-check-circle" />
                  </a>
                  Cashew can be drid and it lasts for a long time.
                </li>
                <li>
                  <a href="#">
                    <i className="fas fa-check-circle" />
                  </a>
                  Cashew can be drid and it lasts for a long time.
                </li>
                <li>
                  <a href="#">
                    <i className="fas fa-check-circle" />
                  </a>
                  Cashew can be drid and it lasts for a long time.
                </li>
              </ul>
              <div className="line" />
              <h6>What currency do you want to invest with?</h6>
              <form className="select">
                <label className="checkbox-inline mr-4">
                  <input type="checkbox" id="currency" defaultValue />
                  Naira
                </label>
                <label className="checkbox-inline">
                  <input type="checkbox" id="currency" defaultValue />
                  Dollar
                </label>
              </form>
              <div className="line" />
              <h6 style={{ color: "#00A82D" }}>₦200,000.00/Unit</h6>
              <div className="add-box" />
              <button
                className="btn btn-success my-2 my-sm-0 card-btn"
                type="submit"
              >
                Invest Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Product;
