import "./asset/css/style.scss";

import { Link, Route, BrowserRouter as Router, Switch, useRouteMatch, } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import Investment from "./Investment";
import Payout from "./Payout";
import Profile from "./Profile";
import ScrollToTop from '../../components/ScrollToTop';
import SideBar from '../../components/Sidebar';
import { getUser } from '../../utils';

const DashRoutes = () => {
  let match = useRouteMatch();
  
  useEffect(() => {
    if (getUser()) {
    } else {
      window.location.href = '/login'
    }
  }, [])

  return (
    <div className="dashboard__">
          <div className="body">
    <Router>
      <Header />
        <section id="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
              <div className="wrap">
                <SideBar />
                <ScrollToTop />
                <Switch>
                  <Route exact path={`${match.path}`} component={Home} />
                  <Route path={`${match.path}/investments`} component={Investment} />
                  <Route path={`${match.path}/payouts`}component={Payout} />
                  <Route path={`${match.path}/profile`} component={Profile} />
                  {/* // Add 404 routes */}
                </Switch>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      </section>
      </Router>
      </div>
      </div>
  );
}

export default DashRoutes;

//TODO Add 404 routes and pages