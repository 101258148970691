import AboutBg1 from "./asset/img/grad.png";
import Mission from "./asset/img/focus.png";
import React from "react";
import Vision from "./asset/img/target.png";
import { setPageTitle } from "../../utils";
import { useEffect } from "react";

import Video from "./asset/img/fbg2.jpg";
import Uju from "./asset/img/team/uju.png";
import Oge from "./asset/img/team/oge.jpg";
import Erhun from "./asset/img/team/erhun.jpg";
import Robert from "./asset/img/team/robert.jpg";
import Unwana from "./asset/img/team/unwana.jpg";

function AboutUs() {
  useEffect(() => {
    setPageTitle("About us");
  }, []);

  return (
    <div>
      <section id="about-us">
        <img className="img-fluid abt-bg1" src={AboutBg1} />
        {/* <img className="img-fluid abt-bg2" src={AboutBg2} /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-txt">
                <h4>
                  We believe that support is
                  <br />
                  the way to make progress.
                </h4>
                <p>
                  With practical trade expertise, we offer our investors an
                  <br />
                  opportunity to promote trade in Africa and ultimately create
                  <br />
                  wealth for individuals and nation states.
                </p>
              </div>
            </div>
            <div className="col-lg-6" align="right">
              <div className="farm-video">
                <a href="#">
                  <img className="africa img-fluid" src={Video} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="mission-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="vision">
                <img className="mission-img img-fluid" src={Mission} />
                <h4>Who we are</h4>
                <p>
                  We believe that our name African Trade Invest speaks to what
                  we do. ATI is a Pan African Trade and Investment company. We
                  have created a double pronged business model for funding and
                  investing in African trade.
                </p>
                <p>
                  Our mission is to create a safe and reliable environment for
                  making investors and genuine African Businesses Successful and
                  our vision is to create wealth for all stakeholders by
                  facilitating African Trade
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="vision">
                <img className="mission-img img-fluid" src={Vision} />
                <h4>What we do</h4>
                <p>
                  Suppliers of various African products who have confirmed
                  orders for their products backed by an acceptable payment
                  terms can access funds for the execution of their orders, we
                  call the orders Project
                </p>
                <p>
                  Upon project verification, these funding needs are broken down
                  into units and the units are offered to members of the public
                  to invest. Presently, investment may be in Nigerian Naira or
                  United States Dollars Investors receive an incredible share of
                  profit (SOP).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-img">
        <div className="container">
          <div className="row">
            <div className="col-lg-12" align="center">
              <h4>Meet Our Team</h4>
              <p>
                We are a team of young dynamic innovators sowing the seeds of
                qualitative trade out of Africa with a mission to create
                sustainable
                <br />
                wealth for individuals, communities, organizations and nation
                states in Africa.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="team">
                <div className="meet-us">
                  <div className="img-wrap">
                    <img className="team-img" src={Uju} />
                  </div>
                  <h5>Uju Uzo-Ojinnaka</h5>
                  <p>Founder / CEO</p>
                </div>
                <div className="meet-us">
                  <div className="img-wrap">
                    <img className="team-img" src={Oge} />
                  </div>
                  <h5>Oge Benlonwu</h5>
                  <p>Chief Legal Officer</p>
                </div>
                <div className="meet-us">
                  <div className="img-wrap">
                    <img className="team-img" src={Erhun} />
                  </div>
                  <h5>Erhun Abbe</h5>
                  <p>Digital Lead</p>
                </div>
                <div className="meet-us">
                  <div className="img-wrap">
                    <img className="team-img" src={Robert} />
                  </div>
                  <h5>Robert Ebafua</h5>
                  <p>Developer</p>
                </div>
                <div className="meet-us">
                  <div className="img-wrap">
                    <img className="team-img" src={Unwana} />
                  </div>
                  <h5>Unwana Usoro</h5>
                  <p>Product Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="invest-today">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="invest-today-wrap">
                <div className="row">
                  <div className="col-lg-8">
                    <p>Get Started Now</p>
                    <h2>Start investing today</h2>
                  </div>
                  <div className="col-lg-4" align="right">
                    <button className="btn btn-success">
                      Sign up for free
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
