import { useEffect, useState } from "react";

import React from "react";
import Request from "../../apiClient";
import { setPageTitle } from "../../utils";
import { toast } from "react-toastify";

function Signup() {
  const [code, setCode] = useState("");
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    houseAddress: "",
    loading: false,
    agree: false,
    hasRegistered: false,
  });

  useEffect(() => {
    setPageTitle("Create an Account");
  }, []);

  const register = async () => {
    if (!user.agree) {
      toast.error("You have not accepted our terms and condition");
      return;
    }
    const name = user.name.split(" ");
    if (name.length <= 1) {
      toast.error("Please enter your first and last name separated by space");
      return;
    }
    if (
      user.email === "" ||
      user.password === "" ||
      name[0] === "" ||
      name[1] === ""
    ) {
      toast.error("Email or password cannot be empty");
      return;
    }

    if (user.password !== user.confirmPassword) {
      toast.error("The password you entered does not match");
      return;
    }

    setUser({ ...user, loading: true });
    await Request("POST", "investor/register", {
      email: user.email,
      password: user.password,
      firstName: name[0],
      lastName: name[1],
      phone: user.phoneNumber,
      houseAddress: user.houseAddress,
    })
      .then((response) => {
        if (response.data.status === true) {
          setUser({ ...user, loading: false, hasRegistered: true });
        } else {
          setUser({ ...user, loading: false });
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        setUser({ ...user, loading: false });
        console.error(e);
      });
  };

  const activateAccount = async () => {
    if (code === "") {
      toast.error("Activation code cannot be empty");
      return;
    }
    setUser({ ...user, loading: true });
    await Request("GET", "investor/verify/" + code, null)
      .then((response) => {
        if (response.data.status === true) {
          window.location.href = "/login";
        } else {
          setUser({ ...user, loading: false });
          toast.error(response.data.message);
        }
      })
      .catch((e) => {
        setUser({ ...user, loading: false });
        console.error(e);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ height: "100vh" }}>
        <div className="col-lg-6 bg-img-default" id="login"></div>
        <div className="col-lg-6">
          <div className="row bg-white">
            <div className="forms">
              {!user.hasRegistered ? (
                <div className="login-form">
                  <h5>Create an account</h5>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Fullname"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          phoneNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          houseAddress: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          password: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Passsword"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          confirmPassword: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="checkbox">
                    <label className="remember-me">
                      <input
                        className="check-input"
                        type="checkbox"
                        onChange={(e) => {
                          setUser({
                            ...user,
                            agree: !user.agree,
                          });
                        }}
                      />
                      By signing up, you agree to our terms and conditions
                    </label>
                  </div>

                  <button
                    className="btn btn-success my-2 my-sm-0 card-btn"
                    type="submit"
                    onClick={() => register()}
                  >
                    SIGNUP
                  </button>
                  <p className="signup-link">
                    Already have an account? <a href="/login">Login</a>
                  </p>
                </div>
              ) : (
                <div className="login-form">
                  <h5>Veirfy your account</h5>
                  <p>An activation code has been sent to your email</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Activation code"
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-success my-2 my-sm-0 card-btn"
                    type="submit"
                    onClick={() => {
                      activateAccount();
                    }}
                  >
                    VERIFY ACCOUNT
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
