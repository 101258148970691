import React from "react";
import { setPageTitle } from "../../utils";
import { useEffect } from "react";

function Partnership() {
  useEffect(() => {
    setPageTitle("Partnerships");
  }, []);

  return (
    <div>
      <section id="partnership">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Partnerships</h1>
              <p>
                Our core business in ATI is to facilitate trade in Africa. We do
                this by making funds available to genuine African businesses to
                execute verified projects. And through the commitment of our
                investors, we have been able to fund hundreds of trade
                transactions across Africa.
              </p>
              <p>
                As we scale our business, we are seeking partnerships in areas
                of Trade and Investments.
              </p>
              <h2>Trade Partners</h2>
              <p>
                To make sure that every genuine trade business in Africa can
                access the funds they need to trade successfully, we are looking
                to partner with reputable organizations with the capacity to
                connect these businesses to ATI. We only require a confirmation
                of the authenticity of transactions which require funding, for
                which our team will verify. As soon as all of the requirements
                are provided and confirmed, the required funds will be released
                to facilitate the particular project according to the terms.
                <br />
                <br />
                African Trade Invest will continue to facilitate trade across
                Africa, and, with the help of a Trade Partner, we will be able
                to reach those genuine businesses in need. Are you capable of
                becoming one of our Trade Partners in Africa? Please contact us,
                as we would be glad to hear from you.
              </p>
              <h2>Investment Partner</h2>
              <p>
                In order to provide the needed funding to genuine African
                businesses, we rely on the commitment of our investors who have
                continued to support trade in Africa. Moving forward, we seek to
                partner with corporate investors who not only wish to support
                African trade themselves, but who also exercise an influence
                across stakeholders in Africa and the global corporate
                community.
                <br />
                <br />
                From anywhere in the world, corporate organizations can now
                directly facilitate trade in Africa through African Trade
                Invest. We welcome you to become an Investment Partner with ATI,
                as we promote trade not aid.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="invest-today">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="invest-today-wrap">
                <div className="row">
                  <div className="col-lg-8">
                    <p>Interested in becoming a partner?</p>
                    <h2>Contact us</h2>
                  </div>
                  <div className="col-lg-4" align="right">
                    <button className="btn btn-success">Contact us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Partnership;
