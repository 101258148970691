import React, { useEffect, useState } from "react";
import { getToken, getUser, setPageTitle } from "../../utils";

import NoImage from "./asset/img/no_image.png";
import NumberFormat from "react-number-format";
import Request from "../../apiClient";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { usePaystackPayment } from "react-paystack";

function InvestmentDetails() {
  const [loading, setLoading] = useState([]);
  const [commodity, setCommodity] = useState([]);
  var { commodityID } = useParams();

  var email = "";
  if (getUser() !== "") {
    email = JSON.parse(getUser()).user.email;
  }
  const [config, setconfig] = useState({
    reference: new Date().getTime(),
    email: email,
    amount: 1000000,
    currency: "ngn",
    publicKey: "sk_live_25f622b174db9a3f8fbfc5b6ce8665848ad94f91",
  });
  const initializePayment = usePaystackPayment(config);
  const processRequest = () => {
    if (getUser() === "") {
      toast.error("You are not logged in, kindly sign in or create an account");
      return;
    }
    if (config.amount < 1000000) {
      toast.error("Amount is below minium accepted ");
      return;
    }
    config.amount = config.amount * 100;
    initializePayment(onSuccess, onClose);
  };
  const onSuccess = (reference) => {
    const body = {
      pricePerUnit: commodity.pricePerUnit,
      purchasedUnits: config.amount / commodity.pricePerUnit,
      currency: config.currency,
      interestRate: 8,
      duration: "4",
      durationPeriod: "month",
      paymentMethod: "paystack",
      CommodityId: commodity.id,
      InvestorId: JSON.parse(getUser()).user.id,
      InvestmentStatusId: "ongoing",
      investmentDate: new Date(),
    };
    Request("POST", "commodity-investment/create/website", body, {
      Authorization: getToken(),
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            "You have successfully created an investment, check your dashboard to manage your investment"
          );
        } else {
          toast.error(
            "Failed to create investment, if your account was debited contact us."
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onClose = () => {
    toast.error(
      "Failed to process investment. If problem persist kindly contact us directly"
    );
  };

  useEffect(() => {
    setPageTitle("Investment Information");
    getCommodity();
  }, []);

  const getCommodity = () => {
    setLoading(true);
    Request("GET", "commodity/" + commodityID)
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          setCommodity(response.data.data.commodity);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error);
        }
      });
    //TODO calculate usd rates
  };
  return (
    <section id="investment-info">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 ">
            <div className="investment-info-lhs">
              <div className="commodity-img">
                <img src={commodity.defaultMedia} />
              </div>
              <h1>{commodity.title}</h1>
              <p
                dangerouslySetInnerHTML={{ __html: commodity.description }}
              ></p>
            </div>
          </div>
          {/* <div className="col-lg-5">
            <div className="investment-info-rhs">
              <div className="sidebar">
                <h1>Invest in this Commodity</h1>
                <div>
                  <p className="label">Select Currency</p>
                  <div
                    className="form-check form-check-inline"
                    onClick={(val) => {
                      setconfig({
                        ...config,
                        currency: "ngn",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      checked={config.currency === "ngn"}
                    />
                    <label
                      className="form-check-label custom-radio-btn"
                      htmlFor="inlineRadio1"
                    >
                      Naira (₦)
                    </label>
                  </div>
                  <div
                    className="form-check form-check-inline"
                    onClick={(val) => {
                      setconfig({
                        ...config,
                        currency: "usd",
                      });
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      defaultValue="option1"
                      checked={config.currency === "usd"}
                    />
                    <label
                      className="form-check-label custom-radio-btn"
                      htmlFor="inlineRadio2"
                    >
                      USD ($)
                    </label>
                  </div>
                  <p className="label">Share of Profit (SOP)</p>
                  <h2>
                    {config.currency === "ngn" ? commodity.interestRate : 5}%
                  </h2>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Enter Investment amount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={config.amount}
                      onChange={(val) => {
                        setconfig({
                          ...config,
                          amount: val.target.value,
                        });
                      }}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Minimum Investment is NGN 1,000,000 or USD 5,000
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      At maturity you earn
                    </label>
                    <div className="form-control">
                      <NumberFormat
                        value={Number(
                          ((config.currency === "ngn"
                            ? commodity.interestRate
                            : 5) *
                            config.amount) /
                            100 +
                            Number(config.amount)
                        ).toFixed()}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={config.currency === "ngn" ? "₦" : "$"}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      processRequest();
                    }}
                  >
                    Invest Now
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default InvestmentDetails;
