import { BrowserRouter, Route, Switch, link } from "react-router-dom";

import DashRoutes from "./pages/dashboard/Routes";
import NotFound from './pages/Notfound';
import PublicRoutes from "./pages/public/Routes";
import React from "react";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/dashboard" component={DashRoutes} />
        <Route path="/" component={PublicRoutes} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
