import { getToken, getUser, setPageTitle } from "../../utils";
import { useEffect, useState } from "react";

import NumberFormat from "react-number-format";
import PayStackIcon from "./asset/img/paystack-s.png";
import React from "react";
import Request from "../../apiClient";
import Secure from "./asset/img/lock.svg";
import Union from "./asset/img/union-bank.svg";
import Zenith from "./asset/img/zenith-bank.svg";
import moment from "moment";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import { useParams } from "react-router";
import { usePaystackPayment } from "react-paystack";

const Buffer = require('buffer').Buffer;

function InvestmentSummary() {
  var { investmentType, amount, currency, duration, durationPeriod } =
    useParams();
  useEffect(() => {
    setPageTitle("Investment Information");
  }, []);

  var email = "";
  if (getUser() !== "") {
    email = JSON.parse(getUser()).user.email;
  }
  const [config, setconfig] = useState({
    reference: new Date().getTime(),
    email: email,
    amount: amount * 100,
    currency: currency,
    publicKey: "pk_test_6c075eec5ff3d6f5bc3caf67471721a224528c9d",
    // publicKey: "pk_live_439b9848112119fe6d7743c66c9d8159792cf303",
  });
  const initializePayment = usePaystackPayment(config);
  const processRequest = () => {
    if (!window.confirm("Are you sure you want to proceed")) {
      return
    }
    
    if (getUser() === "") {
      toast.error("You are not logged in, kindly sign in or create an account");
      return;
    }
    if (config.amount < 100000000) {
      toast.error("Amount is below minium accepted ");
      return;
    }
    initializePayment(onSuccess, onClose);
  };
  const onSuccess = (reference) => {
    if (investmentType === "project") {
      const body = {
        amountInvested:config.amount / 100,
        pricePerUnit: 500000,
        purchasedUnits: config.amount / 100 / 500000,
        currency: config.currency,
        interestRate: config.currency === "USD" ? 5 : 8,
        duration: duration,
        durationPeriod: durationPeriod,
        paymentMethod: "paystack",
        CommodityId: 62,
        InvestorId: JSON.parse(getUser()).user.id,
        InvestmentStatusId: 1,
        investmentDate: new Date(),
      };
      Request("POST", "commodity-investment/create/website", body, {
        Authorization: getToken(),
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              "You have successfully created an investment, check your dashboard to manage your investment"
            );
            window.location.href = "/investment-success";
          } else {
            toast.error(
              "Failed to create investment, if your account was debited contact us."
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      const body = {
        premiumID: "PRE0001",
        userId: JSON.parse(getUser()).user.id,
        amountInvested: config.amount / 100,
        currency: config.currency,
        interestRate: config.currency === "USD" ? 5 : 8,
        investmentDate: new Date(),
        duration: duration,
        durationPeriod: durationPeriod,
        InvestmentStatusId: 1,
        source: "website",
        type: investmentType.toLowerCase(),
      };
      Request("POST", "premium/investment/create", body, {
        Authorization: getToken(),
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              "You have successfully created an investment, check your dashboard to manage"
            );
            window.location.href = "/investment-success";
          } else {
            toast.error(
              "Failed to create investment, if your account was debited contact us."
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const onClose = () => {
    toast.error(
      "Failed to process investment. If problem persist kindly contact us directly"
    );
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [openFileSelector, { filesContent, loading }] = useFilePicker({});
  const [uploadProof, setUploadProof] = useState(false);
  const [uploadLoading, setUploading] = useState(false);

  const upload = async () => {
    if (filesContent.length === 0) {
      toast.error("No files selected yet.");
      return
    }
    setUploading(true);
    filesContent.map((data, i) => {
      const buff = Buffer.from(data.content);
      console.log(buff);
      Request("POST", "upload-to-aws", {
        name: data.name,
        buffer: data.content,
      })
        .then((response) => {
          setUploading(false);
          console.log(response);
          if (response.status === 200) {
            toast.success("Uploaded payment confirmation");
            console.log(response);
            reserveInvestment(response.data.data.Location);
          } else {
            toast.error("Failed to upload payment receipt.");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  const reserveInvestment = async (proof) => {
    if (!window.confirm("Are you sure you want to proceed")) {
      return
    }
    setUploading(true);
    if (investmentType === "Project") {
      const body = {
        amountInvested:config.amount / 100,
        pricePerUnit: 500000,
        purchasedUnits: config.amount / 100 / 500000,
        currency: config.currency,
        interestRate: config.currency === "USD" ? 5 : 8,
        duration: duration,
        durationPeriod: durationPeriod,
        paymentMethod: "reserve",
        CommodityId: 62,
        InvestorId: JSON.parse(getUser()).user.id,
        investmentDate: new Date(),
        reserve: true,
        paymentRef: proof,
        InvestmentStatusId: 6
      };
      Request("POST", "commodity-investment/create/website", body, {
        Authorization: getToken(),
      })
        .then((response) => {
          setUploading(false);
          if (response.status === 201) {
            toast.success(
              "You have successfully created an investment, check your dashboard to manage your investment"
            );
            window.location.href = "/investment-success";
          } else {
            toast.error(
              "Failed to create investment, if your account was debited contact us."
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      const body = {
        premiumID: "PRE0001",
        userId: JSON.parse(getUser()).user.id,
        amountInvested: config.amount / 100,
        currency: config.currency,
        interestRate: config.currency === "USD" ? 5 : 8,
        investmentDate: new Date(),
        duration: duration,
        durationPeriod: durationPeriod,
        source: "website",
        reserve: true,
        type: investmentType.toLowerCase(),
        paymentRef: proof,
        InvestmentStatusId: 6
      };
      Request("POST", "premium/investment/create", body, {
        Authorization: getToken(),
      })
        .then((response) => {
          setUploading(false);
          if (response.status === 201) {
            toast.success(
              "You have successfully created an investment, check your dashboard to manage"
            );
            window.location.href = "/investment-success";
          } else {
            toast.error(
              "Failed to create investment, if your account was debited contact us."
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
  return (
    <div>
      <section id="inv-summmary">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h2>
                Payment
                <span>
                  <nav>
                    <div
                      className="nav nav-pills payment-tabs"
                      id="nav-tab"
                      role="tablist"
                    >
                      <a
                        className="nav-item nav-link active"
                        id="nav-home-tab"
                        data-toggle="tab"
                        href="#nav-home"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Card Payment
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="nav-profile-tab"
                        data-toggle="tab"
                        href="#nav-profile"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Bank Payment
                      </a>
                    </div>
                  </nav>
                </span>
              </h2>
              <div className="inv-summary-wrap">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div>
                      <h3>
                        Card Information
                        <span>
                          <img src={Secure} /> Secure server
                        </span>
                      </h3>
                      <div className="line" />
                      <img
                        class="cards"
                        style={{ height: 59, marginRight: 20 }}
                        src={PayStackIcon}
                      />{" "}
                      <button
                        type="submit"
                        className="btn btn-success mb-2"
                        onClick={() => {
                          processRequest();
                        }}
                      >
                        Pay with card
                      </button>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <h2>Payment Instructions</h2>
                    {/* <p>
                      For Transfers please make payments to the following
                      accounts below.{" "}
                      <span>
                        Send proof of payment to payments@africantradeinvest.com
                      </span>{" "}
                      We will send you a confirmation email when your deposit is
                      confirmed.{" "}
                      <span>
                        Please note that if we are unable to confirm payment
                        with 24hrs investment will be automatically cancelled.
                      </span>
                    </p> */}
                    <p>
                      For Transfers please make payments to the following
                      accounts below. 
                      <span>
                        Click on “I have made payment” button below and upload
                        proof of payment.
                      </span>{" "}
                      Once payment is confirmed your investment will become
                      active.
                    </p>
                    <p>
                      For Bank deposits,{" "}
                      <span>
                        Send proof of payment to payments@africantradeinvest.com
                      </span>
                       We will send you a confirmation email once your deposit
                      is confirmed. 
                    </p>
                    <p>
                      <span>
                        Please note that if we are unable to confirm payment
                        within 24hrs, investment will be automatically
                        cancelled.
                      </span>
                    </p>
                    <div className="line" />
                    <h3 className>Naira Accounts</h3>
                    <div className="bank-wrap">
                      <img src={Union} />
                      <h3>Union Bank</h3>
                      <p>ATIDOTCOM LTD</p>
                      <p className="acctno">0117800738</p>
                    </div>
                    <div className="bank-wrap">
                      <img src={Zenith} />
                      <h3>Zenith Bank</h3>
                      <p>ATIDOTCOM LTD</p>
                      <p className="acctno">1016502803</p>
                    </div>
                    <div className="line"></div>
                    <h3 className="">Dollar Accounts</h3>
                    <div className="bank-wrap">
                      <img src={Union} />
                      <h3>Union Bank</h3>
                      <p>ATIDOTCOM LTD</p>
                      <p className="acctno">0117821155</p>
                    </div>
                    <div className="bank-wrap">
                      <img src={Zenith} />
                      <h3>Zenith Bank</h3>
                      <p>ATIDOTCOM LTD</p>
                      <p className="acctno">5071137644</p>
                    </div>
                    {!uploadProof ? (
                      <div>
                        <button
                          type="submit"
                          className="btn btn-success mb-2 mt-5 mr-3"
                          onClick={() => {
                            setUploadProof(true);
                          }}
                        >
                          I have made payment
                        </button>
                        <button
                          type="submit"
                          className="btn btn-success mb-2 mt-5"
                          onClick={() => {
                            reserveInvestment("");
                          }}
                        >
                          {uploadLoading ? "loading..." : "Bank Deposit"}
                        </button>
                      </div>
                    ) : (
                      <div>
                        {filesContent.length > 0 ? (
                          <span>{filesContent[0].name}</span>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-info mb-2 mt-5 mr-3"
                            onClick={() => {
                              openFileSelector();
                            }}
                          >
                            Upload Proof of Payment
                          </button>
                        )}
                        <button
                          type="submit"
                          className="btn btn-success mb-2 mt-5"
                          onClick={() => {
                            upload();
                          }}
                        >
                          {uploadLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-1">
              <h2>Investment Summary</h2>
              <div className="line" />
              <div className="inv-summary-wrap-right">
                <h3>{capitalizeFirstLetter(investmentType)}</h3>
                <h4>
                  Duration
                  <span>
                    {duration} {durationPeriod}
                  </span>
                </h4>
                <h4>
                  Maturity Date
                  <span>
                    {investmentType === "project"
                      ? moment().add(4, "m").format("YYY-MM-DD")
                      : moment()
                          .add(parseInt(duration), durationPeriod)
                          .format("YYYY-MM-DD")}
                  </span>
                </h4>
                <h4>
                  Amount
                  <span>
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency + " "}
                    />
                  </span>
                </h4>
                <div className="line" />
                <h4>
                  Subtotal
                  <span>
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency + " "}
                    />
                  </span>
                </h4>
                <div className="line" />
                <h5>
                  Total
                  <span>
                    <NumberFormat
                      value={amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={currency + " "}
                    />
                  </span>
                </h5>
                <div className="line" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default InvestmentSummary;
