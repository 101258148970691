import { getToken, getUser } from '../../utils';

import React from "react";
import { toast } from 'react-toastify';
import { usePaystackPayment } from 'react-paystack';
import { useState } from 'react';

function Premium() {
// TODO format number to thousands
  const [duration, setDuration] = useState('6 months')
  var email = ""
  if (getUser() !== "") {
    email = JSON.parse(getUser()).user.email
  }
  const [config, setconfig] = useState({
    reference: (new Date()).getTime(),
    email: email,
    amount: 0,
    currency:'ngn',
    publicKey: 'sk_live_25f622b174db9a3f8fbfc5b6ce8665848ad94f91',
  })
  const initializePayment = usePaystackPayment(config);
  const processRequest = () => {
    if (getUser() === '') {
      toast.error("You are not logged in, kindly sign in or create an account");
      return;
    }
    if (config.amount/100 < 1000000) {
      toast.error("Amount is below minium accepted ");
      return;
    }
    const dur = duration.split(" ")
    if (dur.length < 2) {
      toast.error("Invalid duration set ");
      return;
    }
    if (isNaN(dur[0])) {
      toast.error("Duration value is not a number ");
      return;
    }
    if (dur[1] !== "month" && dur[1] !== "year") {
      toast.error("Invalid value for duration period ");
      return;
    }
    initializePayment(onSuccess, onClose)
  }
  const onSuccess = (reference) => {
    const body = {
      premiumID: "PRE0001",
      userId: JSON.parse(getUser()).user.id,
      amountInvested: config.amount/100,
      currency: config.currency,
      interestRate: 8,
      investmentDate: new Date(),
      duration: duration.split(" ")[0],
      durationPeriod: duration.split(" ")[1],
      source: "website"
    }
    Request(
      'POST',
      'premium/investment/create',body,{ Authorization: getToken() }).then((response) => {
        if (response.status === 201) {
          toast.success('You have successfully created an investment, check your dashboard to manage')
        } else {
          toast.error('Failed to create investment, if your account was debited contact us.')
        }
    }).catch((e => {
      console.error(e)
    }))
  };
  
  const onClose = () => {
    toast.error("Failed to process investment. If problem persist kindly contact us directly");
  }


  return (
    <section id="main-content">
      <div className="container">
        <div className="row">
          <div classs="col-lg-12">
            <div className="premium">
              <h5>Premium Investment</h5>
              <p>
                The cashew seed is often considered a nut in the culinary sense;
                the cashew nut is eaten on its own, used in recipes or processed
                into cashew seed or cashew butter. like the tree the nut is
                often only called cashew The cashew seed is often considered a
                nut in the culinary sense; the cashew nut is eaten on its own,
                used in recipes or processed into cashew seed or cashew butter.
                like the tree the nut is often only called cashew The cashew
                seed is often considered a nut in the culinary sense; the cashew
                nut is eaten on its own, used in recipes or processed into
                cashew seed or cashew butter.
              </p>
              <p>
                {" "}
                like the tree the nut is often only called cashew The cashew
                seed is often considered a nut in the culinary sense; the cashew
                nut is eaten on its own, used in recipes or processed into
                cashew seed or cashew butter. like the tree the nut is often
                only called cashewThe cashew seed is often considered a nut in
                the culinary sense; the cashew nut is eaten on its own, used in
                recipes or processed into cashew seed or cashew butter. like the
                tree the nut is often only called cashew The cashew seed is
                often considered a nut in the culinary
              </p>
              <div className="line" />
              <h6>What currency do you want to invest with?</h6>
              <form className="select">
                <label className="checkbox-inline mr-4" id>
                  <input type="radio" id="currency" checked={config.currency === 'ngn'}
                    onChange={(val) => {
                        setconfig({
                          ...config,
                          currency: 'ngn'
                        })
                      }}/>
                  Naira
                </label>
                <label className="checkbox-inline">
                  <input type="radio" id="currency" checked={config.currency === 'usd'}
                  onChange={(val) => {
                    setconfig({
                      ...config,
                      currency: 'usd'
                    })
                  }}/>
                  Dollar
                </label>
              </form>
              <div className="line" />
              <div className="row">
                <div className="col-lg-6">
                  <h6>Enter the amount you wish to invest in: {config.currency.toUpperCase()}</h6>
                  <div className="form-group">
                    <input
                      inputmode="numeric"
                      type="number"
                      className="form-control"
                      placeholder="2,000,000.00"
                      onChange={(val) => {
                        setconfig({
                          ...config,
                          amount: val.target.value*100
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h6>Select your preferred duration:</h6>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="6 months/1 year"
                      onChange={(val) => {
                        setDuration(val.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <button
                className="btn btn-success my-2 my-sm-0 card-btn"
                type="submit"
                onClick={() => {
                  processRequest()
              }}
              >
                Proceed to pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Premium;
