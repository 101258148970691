import {
  IoAppsOutline,
  IoCashOutline,
  IoHomeOutline,
  IoListOutline,
  IoLogInOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { Link, useRouteMatch } from "react-router-dom";

import { IconContext } from "react-icons";
import { isActive } from "../utils";
import { logout } from "../utils";

function SideBar() {
  let match = useRouteMatch();
  return (
    <IconContext.Provider
      value={{ size: "1.3em", style: { marginRight: "15%" } }}
    >
      <div className="lhs">
        <div className="sidebar-box shadow-sm">
          <ul className="nav flex-column sidebar-menu">
            <li className={`${isActive("/dashboard")}`}>
              <Link to={`/dashboard`}>
                <IoHomeOutline />
                Home
              </Link>
            </li>
            <li className={`${isActive("/dashboard/investments")}`}>
              <Link to={`/dashboard/investments`}>
                <IoAppsOutline />
                My Investment
              </Link>
            </li>
            {/* <li>
              <Link onClick={() => window.location.href = '/investments'}>
              <IoListOutline />
              Commodities
            </Link>
        </li> */}
            <li className={`${isActive("/dashboard/payouts")}`}>
              <Link to={`/dashboard/payouts`}>
                <IoCashOutline />
                Payouts
              </Link>
            </li>
            <li className={`${isActive("/dashboard/profile")}`}>
              <Link to={`/dashboard/profile`}>
                <IoPersonOutline />
                Profile
              </Link>
            </li>
            <li className="_link">
              <a
                onClick={() => {
                  logout();
                }}
              >
                <IoLogInOutline />
                logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </IconContext.Provider>
  );
}

export default SideBar;
