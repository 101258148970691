import "./asset/css/style.scss";
import "./asset/css/hover-min.css";

import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
} from "react-router-dom";

import AboutUs from "./AboutUs";
import Partnership from "./Partnership";
import ContactUs from "./ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./Home";
import Investment from "./Investment";
import InvestmentDetails from "./InvestmentDetails";
import InvestmentSuccess from "./InvestmentSuccess";
import InvestmentSummary from "./InvestmentSummary";
import Login from "./Login";
import Premium from "./Premium";
import Product from "./Product";
import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import Signup from "./Signup";

function PublicRoutes() {
  let match = useRouteMatch();
  return (
    <div className="home__">
      <div className="body">
        <Router>
          <Header />
          <ScrollToTop />
          <Switch>
            <Route exact path={match.path} component={Home} />
            <Route exact path={`${match.path}about`} component={AboutUs} />
            <Route
              exact
              path={`${match.path}partnership`}
              component={Partnership}
            />
            <Route
              exact
              path={`${match.path}investments`}
              component={Investment}
            />
            <Route
              exact
              path={`${match.path}investment-summary/:investmentType/:amount/:currency/:duration-:durationPeriod`}
              component={InvestmentSummary}
            />
            <Route
              exact
              path={`${match.path}investment-success`}
              component={InvestmentSuccess}
            />
            <Route
              exact
              path={`${match.path}investment-details/:commodityID`}
              component={InvestmentDetails}
            />
            <Route exact path={`${match.path}contact`} component={ContactUs} />
            <Route exact path={`${match.path}login`} component={Login} />
            <Route exact path={`${match.path}signup`} component={Signup} />
            <Route
              exact
              path={`${match.path}premium-investment`}
              component={Premium}
            />
            <Route exact path={`${match.path}product`} component={Product} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default PublicRoutes;
