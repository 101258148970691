import { atiAuthKey, setPageTitle } from '../../utils';
import { useEffect, useState } from 'react';

import BtnLoader from '../../components/btnLoader';
import React from "react";
import Request from '../../apiClient';
import { toast } from 'react-toastify';

function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
    loading: false,
  });
  const [resetPassword, setResetPassword] = useState(false)
  const [passwordReset, setPasswordReset] = useState({ confirmationCode: '', password: '', isReset: false, loading: false })
  useEffect(() => {
    setPageTitle('Login')
  }, [])

  const login = (e) => {
    e.preventDefault();
    if (resetPassword) {
      if (user.email === "" ) {
        toast.error("Email cannot be empty");
        return;
      }
      Request('POST','investor/recover', {
        email: user.email,
      }).then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message);
            setPasswordReset({ ...user, isReset: true });
          } else {
            toast.error(response.data.message);
          }
      }).catch((e) => {
        console.error(e)
      });
      return
    }

    if (user.email === "" || user.password === "") {
      toast.error("Email or password cannot be empty");
      return;
    }

    setUser({ ...user, loading: true });
    Request('POST','investor/login', {
      email: user.email,
      password: user.password,
    }).then((response) => {
        console.log(response)
        if (response.data.status === true) {
          localStorage.setItem(atiAuthKey, JSON.stringify(response.data.data));
          toast.success("Login Successful");
          setUser({ ...user, loading: false });
          window.location.href = "/dashboard/";
        } else {
          setUser({ ...user, loading: false });
          toast.error(response.data.message);
        }
    }).catch((e) => {
      setUser({ ...user, loading: false });
      console.error(e)
    });
  };

  const recover = () => {
     Request(
      'POST',
      'investor/reset-password/' + passwordReset.confirmationCode, {
        'password': passwordReset.password,
    }).then((response) => {
      if (response.status === 200) {
        window.location = "/login"
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
    }).catch((e => {
      console.error(e)
    }))
  }
  return (
    <div className="container-fluid">
      <div className="row" style={{ height: "100vh" }}>
        <div className="col-lg-6 bg-img-default" id="login"></div>
        <div className="col-lg-6">
          <div className="row bg-white" ß>
            <div className="form">
              <form className="login-form">
                <h5>Login to get started</h5>
                {!passwordReset.isReset && <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>}
                {!resetPassword && <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Passsword"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>}
                {passwordReset.isReset && <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirmation Ccode"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>}
                {passwordReset.isReset && <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    onChange={(e) => {
                      setUser({
                        ...user,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>}
                <div className="row">
                  {!resetPassword && <div className="col-lg-7">
                    <div className="tick-box">
                      <label className="remember-me">
                        <input
                          className="check-input"
                          type="checkbox"
                          defaultValue
                        />
                        Remember me
                      </label>
                    </div>
                  </div>}
                  <div className="col-lg-5">
                    <a className="forgot-password" href="#" onClick={() => {
                      setResetPassword(!resetPassword);
                      setPasswordReset({isReset: false })
                    }}>
                      {resetPassword?'Login to account':'Forgot Password?'}
                    </a>
                  </div>
                </div>
                <button
                  className="btn btn-success card-btn"
                  type="submit"
                  onClick={(e) =>  (passwordReset.isReset ? recover():!user.loading && login(e))}>
                  {user.loading ? <BtnLoader /> : resetPassword?'FORGOT PASSWORD':'LOGIN'}
                </button>
                <p className="signup-link">
                  Don't have an account? <a href="/signup">Signup Now</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
