import { Link } from 'react-router-dom';

function NotFound() {
    return (
            <div style={{height:'500px',display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <h1 style={{ color: '#212529' }}>Error 404</h1>
                <Link to={'/'}>Home</Link>
            </div>
    );
}

export default NotFound;