import { getToken, getUser, setPageTitle } from "../../utils";
import { useEffect, useState } from "react";

import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";
import React from "react";
import Request from "../../apiClient";
import moment from "moment";

function Investment() {
  const [commodityInvestments, setCommodityInvestments] = useState([]);
  const [premiumInvestments, setPremiumInvestments] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPageTitle("My Investments");
    if (getUser() !== "") {
      getInvestments();
    }
  }, []);
  const getInvestments = () => {
    setLoading(true);
    Request("GET", "investor/commodity-investments", null, {
      Authorization: getToken(),
    })
      .then((response) => {
        if (response.data.status === true) {
          setCommodityInvestments(response.data.data.rows);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    Request("GET", "investor/premium-investments", null, {
      Authorization: getToken(),
    })
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          setPremiumInvestments(response.data.data.rows);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const userId = JSON.parse(getUser())

  return (
    <>
      {loading ? (
        <div className="loader-state">
          <Loader type="TailSpin" color="#00a82d" height={100} width={100} />
        </div>
      ) : (
        <div className="rhs shadow-sm" id="inv-pg">
          <div className="invest">
            <h6>
              My Investments <span />
              </h6>
              <div className="h-row">
                <p>Commodity Investments</p>
                <a className="table-link" href={`https://admin.api.africantradeinvest.com/api/v1/project/statement/${userId?.user?.id}`} target="_blank" rel="noreferrer" >  View Statement</a>
              </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered inv-table">
              <thead>
                <tr className="header">
                  <th>Amount Invested</th>
                  <th>Unit</th>
                  <th>SOP</th>
                  <th>Maturity Amount</th>
                  <th>Start Date</th>
                  <th>Due Date</th>
                  <th>Satus</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {commodityInvestments.map((data, index) => {
                  return (
                    <tr className="body" index={index}>
                      <td>
                        <NumberFormat
                          value={data.InvestmentPayout.amountInvested}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={data.currency + " "}
                        />
                      </td>
                      <td>{data.purchasedUnits}</td>
                      <td>{data.interestRate}%</td>
                      <td>
                        <NumberFormat
                          value={data.InvestmentPayout.payoutDue}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={data.currency + " "}
                        />
                      </td>
                      <td>
                        {moment(data.investmentDate).format("YYYY/MM/DD")}
                      </td>
                      <td>{moment(data.maturityDate).format("YYYY/MM/DD")}</td>
                      <td>
                        <span className="badge badge-pill badge-warning">
                          {data.InvestmentStatus.title}
                        </span>
                      </td>
                      <td>
                        <a className="table-link" href="#">
                          View
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
            <div className="invest mt-5">
            <div className="h-row">
                <p>Premium Investments</p>
                <a className="table-link" href={`https://admin.api.africantradeinvest.com/api/v1/premium/statement/${userId?.user?.id}`} target="_blank" rel="noreferrer" >  View Statement</a>
              </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered inv-table">
              <thead>
                <tr className="header">
                  <th>Amount Invested</th>
                  <th>Cycle</th>
                  <th>Amount at maturity</th>
                  <th>Duration</th>
                  <th>Start Date</th>
                  <th>Due Date</th>
                  <th>Satus</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {premiumInvestments.map((data, index) => {
                  return (
                    <tr className="body">
                      <td>
                        <NumberFormat
                          value={data.amountInvested}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={data.currency + " "}
                        />
                      </td>
                      <td>{data.paymentCycle}</td>
                      <td>
                        <NumberFormat
                          value={data.maturityAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={data.currency + " "}
                        />
                      </td>
                      <td>
                        {data.duration} {data.durationPeriod}
                      </td>
                      <td>
                        {moment(data.investmentDate).format("YYYY/MM/DD")}
                      </td>
                      <td>{moment(data.maturityDate).format("YYYY/MM/DD")}</td>
                      <td>
                        <span className="badge badge-pill badge-warning">
                        {data.InvestmentStatus.title}
                        </span>
                      </td>
                      <td>
                        <a className="table-link" href="#">
                          View
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Investment;
