import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import Logo from "./../asset/img/logo.svg";
import React from "react";
import { getUser } from "../../../utils";

function Header() {
  const history = useHistory();
  const [user, setUser] = useState({
    isAuthenticated: false,
    firstName: "",
  });

  useEffect(() => {
    const auth = getUser();
    if (auth) {
      setUser({
        isAuthenticated: true,
        firstName: JSON.parse(auth).user.firstName,
      });
    }
  }, []);

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-white">
      <div className="container">
        <div className="navbar-header">
          <Link className="navbar-brand" to="/">
            <img className="logo" src={Logo} />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className=" nav navbar-nav navbar-right ml-5" id="header">
            <li>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/investments">
                Investments
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/about">
                About us
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="/contact">
                Support
              </Link>
            </li>
            {/* <li><Link className="nav-link" to="/contact">Contact</Link></li> */}
          </ul>
          <ul class=" nav navbar-nav navbar-right ml-auto right-menu">
            {!user.isAuthenticated && (
              <li>
                <Link className="nav-link" to="/login">
                  <button
                    class="btn btn-light my-2 my-sm-0 nav-btn-login"
                    type="button"
                  >
                    Login
                  </button>
                </Link>
              </li>
            )}

            <li>
              <Link className="nav-link">
                <button
                  className="btn btn-outline-success my-2 my-sm-0 nav-btn-signup"
                  type="button"
                  onClick={() => {
                    !user.isAuthenticated
                      ? history.push("/signup")
                      : (window.location.href = "/dashboard/");
                  }}
                >
                  {!user.isAuthenticated ? "Sign Up for free" : "Dashboard"}
                </button>
              </Link>
            </li>
          </ul>
          {/* <form className="form-inline navbar-right my-2 my-lg-0">
            <button
              className="btn btn-outline-success my-2 my-sm-0 nav-btn"
              type="button"
              onClick={() => {
                !user.isAuthenticated
                  ? history.push("/signup")
                  : (window.location.href = "/dashboard/");
              }}
            >
              {!user.isAuthenticated ? "Register" : "Home"}
            </button>
          </form> */}
        </div>
      </div>
    </nav>
  );
}

export default Header;
